import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import colors from '../styles/colors'

import { GlobalStateContext } from '../context/GlobalContextProvider'

import BlogPostCard from '../components/BlogPostCard'

import Layout from '../components/layout'
import SEO from '../components/seo'

export const pageQuery = graphql`
  query blogIndex {
    allMdx (sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            type
            summary
            date(formatString: "MMMM DD, YYYY")
          }
          fields {
            slug
          }
          timeToRead
        }
      }
    }
  }
`

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  line-height: 1.1;

  color: inherit;

  text-rendering: optimizeLegibility;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
`

const PageTitle = styled.h1`
  font-family: Inter var,sans-serif;
  padding: 30px;

  color: ${props => props.theme.accent}
`

const BlogPosts = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
`

const BlogIndex = ({ data }) => {
  const state = useContext(GlobalStateContext)
  const theme = colors[state.theme]

  const { edges: posts } = data.allMdx

  return (
    <Layout>
      <SEO
        title='Blogs'
      />
      <WrapperDiv>
        <div>
          <PageTitle theme={theme}>Blogs</PageTitle>
        </div>

        <BlogPosts>
          {posts.map(({ node: post }) => (
            <BlogPostCard
              key={post.frontmatter.title}
              title={post.frontmatter.title}
              type={post.frontmatter.type}
              timeToRead={post.timeToRead.toString()}
              slug={post.fields.slug}
              summary={post.frontmatter.summary}
            />
          ))}
        </BlogPosts>

      </WrapperDiv>
    </Layout>
  )
}

BlogIndex.propTypes = {
  data: PropTypes.object.isRequired
}

export default BlogIndex
