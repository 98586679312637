import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from '../styles/colors'
import { GlobalStateContext } from '../context/GlobalContextProvider'
import { Link } from 'gatsby'

const BlogDetails = styled.h3`
  margin: 0.8rem;
  line-height: 1.5;

  font-size: 0.85rem;

  color: ${props => props.theme.meta};

`

const Card = styled(Link)`
  flex-basis: 25%;

  display: flex;
  flex-direction: column;

  padding: 3rem 0.5rem 2.25rem;
  margin: 1rem;

  box-shadow: ${props => props.theme.shadow} 0px 9px 24px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 5px;
  line-height: 1.8;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 9px 24px;

    transition: all 150ms ease-in-out 0s;
    cursor: pointer;

  }

  &:hover ${BlogDetails} {
    color: ${props => props.theme.accent};
  }

  @media only screen and (max-width: 768px) {
    flex-basis: 100%;
  }
`

const BlogTitle = styled.h1`
  line-height: 1.2;
  margin: 2rem;

  color: ${props => props.theme.text};

  font-size: 1.25rem;
  font-family: Inter var, sans-serif;
`

const BlogSummary = styled.p`
  margin: 1rem;

  color: ${props => props.theme.text};

  font-size: 0.9rem;
  font-weight: normal;
`

const Foot = styled.div`
  display: flex;
  justify-content: space-between;
`

const BlogPostCard = (props) => {
  const state = useContext(GlobalStateContext)
  const theme = colors[state.theme]

  return (
    <Card
      theme={theme}
      to={props.slug}
    >
      <BlogDetails theme={theme}>{props.type}</BlogDetails>
      <BlogTitle theme={theme}>{props.title}</BlogTitle>
      <BlogSummary theme={theme}>{props.summary}</BlogSummary>
      <Foot>
        <BlogDetails theme={theme}>{props.author}</BlogDetails>
        <BlogDetails theme={theme}>{props.timeToRead} mins</BlogDetails>
      </Foot>
    </Card>
  )
}

BlogPostCard.propTypes = {
  author: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  timeToRead: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired
}

export default BlogPostCard
